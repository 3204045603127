{
    class aflVaultFilterBarController {

        static get $inject() {
            return ['VaultDataItemTypeConstant', '$timeout'];
        };
        constructor(VaultDataItemTypeConstant, $timeout) {
            this.$timeout = $timeout;
            this.filterCriteria = [{
                name: "Tag1",
                fieldName: "tag1",
                itemType: VaultDataItemTypeConstant.TAG
            }, {
                name: "Tag2",
                fieldName: "tag2",
                itemType: VaultDataItemTypeConstant.TAG
            }, {
                name: "Tag3",
                fieldName: "tag3",
                itemType: VaultDataItemTypeConstant.TAG
            }, {
                name: "Tag4",
                fieldName: "tag4",
                itemType: VaultDataItemTypeConstant.TAG
            },{
                name: "Tag5",
                fieldName: "tag5",
                itemType: VaultDataItemTypeConstant.TAG
            },{
                name: "LTag1",
                fieldName: "ltag1",
                itemType: VaultDataItemTypeConstant.LTAG
            },{
                name: "LTag2",
                fieldName: "ltag2",
                itemType: VaultDataItemTypeConstant.LTAG
            },{
                name: "Created Date",
                fieldName: "createdOn",
                itemType: VaultDataItemTypeConstant.DATE
            },{
                name: "Updated Date",
                fieldName: "updatedOn",
                itemType: VaultDataItemTypeConstant.DATE
            } ];
        }

        removeFilterCriteriaItem(criteria) {
            this.addedFilterCriteria = this.addedFilterCriteria.filter(
                element => element !== criteria);
                this.updateFilter();
            this.$timeout(() => {
                for(let i = 0; i < this.addedFilterCriteria.length; i++) {
                    angular.element(`#filter-item-${i}`)
                        .removeClass('open');
                }
            }, 0);
        }

        selectFilterCriteria(criteria) {
            const criteriaToAdd = {...criteria};
            criteriaToAdd.open = true;
            this.addedFilterCriteria.push(criteriaToAdd);
            this.$timeout(() => {
                angular.element(`#filter-item-${this.addedFilterCriteria.length - 1}`)
                    .addClass('open');
            }, 250);

        }

        updateFilter() {
            this.onUpdateFilterItems({
                items: this.addedFilterCriteria,
                page: 0
            })
        }
    }

    angular.module('adminApp')
        .component('aflVaultFilterBar', {
            controller: aflVaultFilterBarController,
            templateUrl: '/static/javascript/admin/afl-vault/afl-vault-filter-bar/afl-vault-filter-bar.html',
            bindings: {
                onUpdateFilterItems: "&",
                addedFilterCriteria: "="
            }
        });
}
